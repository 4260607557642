import React from "react"
import { Helmet } from "react-helmet"

const BlogSEO = ({ url, title, description, socialImageURL, date, author, schemaMarkup }) => {
  return (
    <Helmet>
      <title>Foretheta | {title}</title>
      <link rel="canonical" href={url} />
      <meta name="description" content={description} />
      <meta name="author" content={author}></meta>

      <meta property="og:locale" content="en_US" />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="article" />
      <meta property="og:url" content={url} />
      <meta property="og:image" content={socialImageURL} />
      <meta property="article:published_time" content={date} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:image" content={socialImageURL} />

      {schemaMarkup && <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>}
    </Helmet>
  )
}

export default BlogSEO

import React from "react"
import { Row, Col, Table } from "reactstrap"
import { graphql } from "gatsby"
import Layout from "../../Layout"
import BlogSEO from "../BlogSEO"
import "./styles.scss"
import Newsletter from "../../Newsletter"
import ReactMarkdown from "react-markdown"
import gfm from "remark-gfm"

const removeWrappers = html => {
  ;["html", "body"].forEach(tag => {
    html = html.replace(`<${tag}>`, "")
    html = html.replace(`</${tag}>`, "")
  })
  return html
}

const BlogLayout = props => {
  const {
    title,
    body_markdown,
    cover_image,
    description,
    social_image,
    published_at,
    edited_at,
    user: { name, profile_image_90: authorImage }
  } = props.data.devArticles.article

  const renderers = {
    table: ({ children }) => {
      return <Table bordered>{children}</Table>
    }
  }

  const url = typeof window !== `undefined` ? window.location.origin + window.location.pathname : null

  const Schema = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: title,
    image: cover_image,
    author: {
      "@type": "Person",
      name: name
    },
    publisher: {
      "@type": "Organization",
      name: "Foretheta",
      logo: {
        "@type": "ImageObject",
        url: "https://foretheta.com/OG_Tag.png"
      }
    },

    datePublished: published_at,
    dateModified: edited_at
  }

  return (
    <Layout>
      <BlogSEO
        url={url}
        title={title}
        socialImageURL={social_image}
        description={description}
        date={published_at}
        author={name}
        schemaMarkup={Schema}
      />
      <div className="blog-content">
        <div>
          <img className="cover-image" src={cover_image || "/cover-image-background-placeholder.jpg"} />
        </div>
        <div className="post-container rounded">
          <Row>
            <Col>
              <Row className="justify-content-center">
                <img src={authorImage} alt="Author Icon" className="rounded-circle post-author-image" />
              </Row>
              <Row className="justify-content-center">
                <h5 className="post-author-name">{name}</h5>
              </Row>
              <Row className="justify-content-center">
                <h4 className="px-4 post-title">{title}</h4>
              </Row>
              <Row className="py-3 px-4">
                <div className="post-content w-100 text-md-center text-sm-left">
                  <ReactMarkdown plugins={[gfm]} renderers={renderers}>
                    {body_markdown}
                  </ReactMarkdown>
                </div>
              </Row>
              <Row className="justify-content-center">
                <Newsletter />
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export const BlogPost = graphql`
  query BlogPost($id: String) {
    devArticles(id: { eq: $id }) {
      article {
        id
        title
        body_html
        body_markdown
        cover_image
        description
        social_image
        published_at
        edited_at
        user {
          name
          profile_image_90
        }
      }
    }
  }
`

export default BlogLayout

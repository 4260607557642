import React, { useState } from "react"
import { Button, Form, Input } from "reactstrap"
import "../../scss/modules/_m-hero-newsletter.scss"
import addToMailchimp from "gatsby-plugin-mailchimp"

const emailRegex = RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export default function newsletter() {
  const INITAIL_ERRORS = {
    email: "",
    exists: ""
  }
  const [email, setEmail] = useState("")
  const [errors, setErrors] = useState(INITAIL_ERRORS)
  const [success, setSuccess] = useState("")

  const handleChange = ({ target: { value } }) => {
    if (value.length === 0) {
      setErrors({ ...errors, email: "", exists: "" })
      setEmail("")
    }
    if (emailRegex.test(value)) {
      setSuccess(false)
      setEmail(value)
      setErrors({ ...errors, email: "", exists: "" })
    } else if (!emailRegex.test(value) && value.length !== 0) {
      setEmail("")
      setSuccess(false)
      setErrors({ ...errors, email: "Invalid Email!", exists: "" })
    }
  }
  const subscribe = async e => {
    e.preventDefault()
    const group = {}
    const key = `group[${process.env.GATSBY_PLUGIN_MAILCHIMP_ID}][${process.env.GATSBY_PLUGIN_MAILCHIMP_VALUE}]`
    group[key] = process.env.GATSBY_PLUGIN_MAILCHIMP_VALUE
    addToMailchimp(email, group)
      .then(data => {
        if (data.result === "success") {
          setSuccess(true)
          setErrors({ ...errors, exists: "" })
        } else {
          setErrors({ ...errors, exists: "This user already exists!" })
          setSuccess(false)
        }
      })
      .catch(error => {
        setSuccess(false)
      })
  }
  return (
    <div className="subscribe-box">
      <p className="subscribe-text">
        Like this article? Join readers who get early access to new articles, guides, updates, and more.
      </p>
      <div>
        {!success && (
          <Form onSubmit={subscribe} className="input-group">
            <Input
              type="text"
              name="email"
              id="email"
              onChange={handleChange}
              placeholder="Enter your email"
              className="form-control"
            />
            <Button type="submit" disabled={email.length === 0}>
              Subscribe
            </Button>
          </Form>
        )}
        {errors.email.length > 0 ? (
          <div className="errorMessage">
            <i className="fa fa-times-circle"></i>
            {errors.email}
          </div>
        ) : null}
        {success ? (
          <div className="successMessage">
            <i className="fa fa-check mr-1"></i>Please check your inbox to confirm your subscription.
          </div>
        ) : (
          <div className="errorMessage">
            {errors.exists.length > 0 ? <i className="fa fa-times-circle mr-1"></i> : null}
            {errors.exists}
          </div>
        )}
      </div>
    </div>
  )
}
